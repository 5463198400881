import { z } from 'zod';

import { SortingCodes } from '@/constants/views/sortingItems';

export enum AggregationType {
  AMOUNT = 'amount',
  SUM = 'sum',
  AVERAGE = 'average',
  PERCENT = 'percent',
}

export enum AmountAggregationCodes {
  TOTAL_NUMBER = 'total_number',
  COMPLETED_AND_TOTAL = 'completed_and_total',
  NOT_COMPLETED_AND_TOTAL = 'not_completed_and_total',
  OVERDUE_AND_TOTAL = 'overdue_and_total',
  QUANTITY_WITH_EMPTY_FIELD = 'quantity_with_empty_field',
  QUANTITY_WITH_FILLED_FIELD = 'quantity_with_filled_field',
}

export enum SumAggregationCodes {
  SUM_BY_COST = 'sum_by_cost',
  SUM_BY_PLANNED_TIME_MINUTES = 'sum_by_planned_time_minutes',
  SUM_BY_CUSTOM_FIELD = 'sum_by_custom_field',
}

export enum AverageAggregationCodes {
  AVERAGE_BY_COST = 'average_by_cost',
  AVERAGE_BY_PLANNED_TIME_MINUTES = 'average_by_planned_time_minutes',
  AVERAGE_BY_CUSTOM_FIELD = 'average_by_custom_field',
}

export enum PercentAggregationCodes {
  COMPLETED = 'completed',
  NOT_COMPLETED = 'not_completed',
  OVERDUE = 'overdue',
  NOT_OVERDUE = 'not_overdue',
  COMPLETED_ON_TIME = 'completed_on_time',
  PERCENT_WITH_EMPTY_FIELD = 'percent_with_empty_field',
  PERCENT_WITH_FILLED_FIELD = 'percent_with_filled_field',
}

export type AggregationFunction =
  | AmountAggregationCodes
  | SumAggregationCodes
  | AverageAggregationCodes
  | PercentAggregationCodes;

export const aggregation = z.object({
  value: z.number().or(z.string()),
  [SortingCodes.Status]: z.number().nullish(),
  [SortingCodes.Priority]: z.string().optional(),
  [SortingCodes.Stage]: z.number().nullish(),
  [SortingCodes.Executor]: z.number().nullish(),
  [SortingCodes.Tag]: z.number().nullish(),
});

export type Aggregation = z.infer<typeof aggregation>;
