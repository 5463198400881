export default {
  types: {
    amount: 'Amount',
    sum: 'Sum',
    average: 'Average',
    percent: 'Percentage',
  },

  amount: {
    totalNumber: 'Cards',
    completedAndTotal: 'Completed cards',
    notCompletedAndTotal: 'Incomplete cards',
    overdueAndTotal: 'Overdue cards',
    quantityWithFilledField: 'By filled field',
    quantityWithEmptyField: 'By empty field',
  },
  sum: {
    sumByCost: 'Cost',
    sumByPlannedTimeMinutes: 'Duration',
    sumByCustomField: 'Custom field',
  },
  average: {
    averageByCost: 'Cost',
    averageByPlannedTimeMinutes: 'Duration',
    averageByCustomField: 'Custom field',
  },
  percent: {
    completed: 'Completed cards',
    notCompleted: 'Incomplete cards',
    overdue: 'Overdue cards',
    notOverdue: 'Not overdue cards',
    completedOnTime: 'Completed on time',
    percentWithEmptyField: 'By empty field',
    percentWithFilledField: 'By filled field',
  },
  fields: {
    executors: 'Executors',
    responsible: 'Responsible',
    deadline: 'Deadline',
    dateStart: 'Start date',
    status: 'Status',
    estimate: 'Duration',
    stage: 'Stage',
    connections: 'Connections',
    tags: 'Tags',
    cost: 'Cost',
  },

  billing: {
    title: 'Results',
    description:
      'Select an important metric directly in the columns. Without unnecessary manual calculations, always in front of your eyes',
  },
};
