<template>
  <div class="icon-with-indicator">
    <div v-if="isActive" class="icon-with-indicator__indicator" :class="classColor"></div>
    <slot />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType } from 'vue';

export enum Colors {
  ErrorLight = 'error-light',
}

export default defineComponent({
  name: 'IconWithIndicator',

  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    color: {
      type: String as PropType<Colors>,
      default: Colors.ErrorLight,
      validator: (value: any) => {
        return Object.values(Colors).includes(value);
      },
    },
  },

  setup(props) {
    const classColor = computed(() => `icon-with-indicator__indicator_color_${props.color}`);

    return { classColor };
  },
});
</script>

<style lang="scss" scoped>
.icon-with-indicator {
  position: relative;

  display: inherit;
  align-items: inherit;
  justify-content: inherit;

  &__indicator {
    position: absolute;
    top: 0;
    right: 0;

    width: 5px;
    height: 5px;

    border-radius: 100%;

    &_color {
      &_error-light {
        background-color: $color-error;
      }
    }
  }
}
</style>
