import { z } from 'zod';

import { taskTypeMinimum } from '@/models/CardTypes';
import { customFieldBase } from '@/models/CustomField';
import { datasource } from '@/models/Datasource';
import {
  AggregationType,
  AmountAggregationCodes,
  AverageAggregationCodes,
  PercentAggregationCodes,
  SumAggregationCodes,
} from '@/models/views/Aggregation';
import { taskElements, ViewTaskFields } from '@/models/views/ViewTaskElements';

import ViewHelper from '@/helpers/views/ViewHelper';

export enum ViewTypes {
  Board = 'board',
  Gantt = 'gantt',
  List = 'list',
  Matrix = 'matrix',
  Calendar = 'calendar',
  Files = 'files',
  Timeline = 'timeline',
  Folder = 'folder',
  Page = 'page',
}

export enum ViewSubtaskCodes {
  Hidden = 'hidden',
  Revealed = 'revealed',
  Separately = 'separately',
}

export const viewBase = z
  .object({
    id: z.number(),
    type: z.nativeEnum(ViewTypes),
    name: z.string(),
    emoji: z.string(),
    icon: z.string(),
    avatar: z.string().nullable(),
    parent_id: z.number().nullable(),
  })
  .transform(({ parent_id, ...value }) => ({
    ...value,
    parent: parent_id,
    icon: ViewHelper.getIcon({ icon: value.icon, type: value.type, emoji: value.emoji }),
  }));

export const viewListItem = viewBase
  .innerType()
  .extend({
    is_favorite: z.boolean(),
    need_expand: z.boolean(),
  })
  .transform(({ parent_id, is_favorite, need_expand, ...value }) => ({
    ...value,
    parent: parent_id,
    needExpand: need_expand,
    isFavorite: is_favorite,
    icon: ViewHelper.getIcon({ icon: value.icon, type: value.type, emoji: value.emoji }),
  }));

export const viewWithDatasource = viewListItem
  .innerType()
  .extend({
    datasource_detail: datasource.nullish(),
  })
  .transform(({ parent_id, is_favorite, need_expand, datasource_detail, ...value }) => ({
    ...value,
    parent: parent_id,
    needExpand: need_expand,
    isFavorite: is_favorite,
    datasource: datasource_detail,
    icon: ViewHelper.getIcon({ icon: value.icon, type: value.type, emoji: value.emoji }),
  }));

export const view = z
  .object({
    id: z.number(),
    type: z.nativeEnum(ViewTypes),
    name: z.string(),
    access: z.string(),
    datasource_detail: datasource.nullish(),
    emoji: z.string(),
    icon: z.string(),
    avatar: z.string().nullable(),
    locked: z.boolean(),
    created_by_id: z.number(),
    default_task_type: z
      .lazy(() => taskTypeMinimum)
      .nullable()
      .optional(),
    public_token: z.string().nullable(),
    can_create_comments_from_sharing: z.boolean(),
    parents: viewBase.optional().array(),
    subtask_display: z.nativeEnum(ViewSubtaskCodes),
    task_elements: taskElements.array(),
    show_column_names: z.boolean(),
    lock_columns: z.boolean(),
    aggregation_type: z.nativeEnum(AggregationType),
    aggregation_function: z.union([
      z.nativeEnum(AmountAggregationCodes),
      z.nativeEnum(SumAggregationCodes),
      z.nativeEnum(AverageAggregationCodes),
      z.nativeEnum(PercentAggregationCodes),
    ]),
    aggregation_field: z.nativeEnum(ViewTaskFields).nullable(),
    custom_field: customFieldBase.pick({ id: true, name: true, type: true }).nullable(),
  })
  .transform(
    ({
      datasource_detail,
      locked,
      default_task_type,
      public_token,
      can_create_comments_from_sharing,
      created_by_id,
      subtask_display,
      task_elements,
      show_column_names,
      lock_columns,
      aggregation_type,
      aggregation_function,
      aggregation_field,
      custom_field,
      ...value
    }) => ({
      ...value,
      icon: ViewHelper.getIcon({ icon: value.icon, type: value.type, emoji: value.emoji }),
      datasource: datasource_detail,
      defaultCardType: default_task_type,
      isLockedStatuses: locked,
      publicToken: public_token,
      canCreateCommentsFromSharing: can_create_comments_from_sharing,
      createdById: created_by_id,
      subtaskDisplay: subtask_display,
      taskElements: task_elements,
      hasHeader: show_column_names,
      isLockedColumns: lock_columns,
      aggregationType: aggregation_type,
      aggregationFunction: aggregation_function,
      aggregationField: aggregation_field,
      aggregationCustomField: custom_field,
    }),
  );

export type View = z.infer<typeof view>;
export type ViewBase = z.infer<typeof viewBase>;
export type ViewListItem = z.infer<typeof viewListItem>;
export type ViewWithDatasource = z.infer<typeof viewWithDatasource>;

export const transformRequestParams = (params: Record<string, any> | string[]): string[] => {
  const transformationMap: Record<string, string> = {
    default_task_type: 'defaultCardType',
    locked: 'isLockedStatuses',
    can_create_comments_from_sharing: 'canCreateCommentsFromSharing',
    subtask_display: 'subtaskDisplay',
    show_column_names: 'hasHeader',
    lock_columns: 'isLockedColumns',
    aggregation_type: 'aggregationType',
    aggregation_function: 'aggregationFunction',
    aggregation_field: 'aggregationField',
    custom_field: 'aggregationCustomField',
  };

  return Array.isArray(params)
    ? params.map((key) => transformationMap[key] || key)
    : Object.keys(params).map((key) => transformationMap[key] || key);
};
