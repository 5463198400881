import { ViewTaskFields } from '@/models/views/ViewTaskElements';

import { wrappers } from '@/components/common/vueSvgIcon/SvgIcon.vue';

import Translation from '@/utils/translation';

import { svgIcons } from '@/constants/icons';

import userImage from '@/assets/images/examples/user.png';
import user2Image from '@/assets/images/examples/user2.png';
import styles from '@/styles/_variables.module.scss';

export const taskElementsToggles = [
  {
    name: new Translation('view.viewGeneral.cardSettings.executors'),
    code: ViewTaskFields.EXECUTORS,
    icon: {
      name: svgIcons.USER,
      wrapper: wrappers.MD,
      color: styles.colorGreyLight,
      height: 14,
    },
  },
  {
    name: new Translation('view.viewGeneral.cardSettings.responsible'),
    code: ViewTaskFields.RESPONSIBLE,
    icon: {
      name: svgIcons.RESPONSIBLE,
      wrapper: wrappers.MD,
      color: styles.colorGreyLight,
      height: 14,
      fill: false,
    },
  },
  {
    name: new Translation('view.viewGeneral.cardSettings.typeCard'),
    code: ViewTaskFields.CARD_TYPE,
    icon: {
      name: svgIcons.TASK,
      wrapper: wrappers.MD,
      color: styles.colorGreyLight,
      height: 14,
      fill: false,
    },
  },
  {
    name: new Translation('view.viewGeneral.cardSettings.tags'),
    code: ViewTaskFields.TAGS,
    icon: {
      name: svgIcons.TASK_TAGS,
      wrapper: wrappers.MD,
      color: styles.colorGreyLight,
      height: 14,
      fill: false,
    },
  },
  {
    name: new Translation('view.viewGeneral.cardSettings.status'),
    code: ViewTaskFields.STATUS,
    icon: {
      name: svgIcons.TASK_STATUS,
      wrapper: wrappers.MD,
      color: styles.colorGreyLight,
      height: 14,
    },
  },
  {
    name: new Translation('view.viewGeneral.cardSettings.informationButtons'),
    code: ViewTaskFields.INFORMATIONAL_BUTTONS,
    icon: {
      name: svgIcons.ATTACHMENT,
      wrapper: wrappers.MD,
      color: styles.colorGreyLight,
      height: 14,
      fill: false,
    },
  },
  {
    name: new Translation('view.viewGeneral.cardSettings.slug'),
    code: ViewTaskFields.SLUG,
    icon: {
      name: svgIcons.POUND,
      wrapper: wrappers.MD,
      color: styles.colorGreyLight,
      height: 14,
      fill: false,
    },
  },
  {
    name: new Translation('view.viewGeneral.cardSettings.interactiveButtons'),
    code: ViewTaskFields.INTERACTIVE_BUTTONS,
    icon: {
      name: svgIcons.CHECK_ALL,
      wrapper: wrappers.MD,
      color: styles.colorGreyLight,
      height: 14,
    },
  },
  {
    name: new Translation('view.viewGeneral.cardSettings.repeat'),
    code: ViewTaskFields.REPEAT,
    icon: {
      name: svgIcons.TASK_REPEAT,
      wrapper: wrappers.MD,
      color: styles.colorGreyLight,
      height: 14,
    },
  },
  {
    name: new Translation('view.viewGeneral.cardSettings.viewers'),
    code: ViewTaskFields.VIEWERS,
    icon: {
      name: svgIcons.EYE,
      wrapper: wrappers.MD,
      color: styles.colorGreyLight,
      height: 14,
    },
  },
  {
    name: new Translation('view.viewGeneral.cardSettings.date'),
    code: ViewTaskFields.DATE,
    icon: {
      name: svgIcons.CALENDAR,
      wrapper: wrappers.MD,
      color: styles.colorGreyLight,
      height: 14,
    },
  },
  {
    name: new Translation('view.viewGeneral.cardSettings.cover'),
    code: ViewTaskFields.COVER,
    icon: {
      name: svgIcons.COVER,
      wrapper: wrappers.MD,
      color: styles.colorGreyLight,
      height: 14,
      fill: false,
    },
  },
  {
    name: new Translation('view.viewGeneral.cardSettings.cost'),
    code: ViewTaskFields.COST,
    icon: {
      name: svgIcons.PRICE,
      wrapper: wrappers.MD,
      color: styles.colorGreyLight,
      height: 14,
    },
  },
];

export const defaultTaskElements = taskElementsToggles.map(({ code }) => ({
  type: code,
  show: code !== ViewTaskFields.COST,
}));

export const mockResponsible = {
  name: 'John',
  avatar: userImage,
  color: '',
  online: true,
};

export const mockExecutors = [
  {
    name: 'Eve',
    avatar: null,
    color: '#9E2F22',
    online: false,
  },
  {
    name: 'Tom',
    avatar: null,
    color: '#E0BB04',
    online: false,
  },
  {
    name: 'Alex',
    avatar: null,
    color: '#E570AF',
    online: false,
  },
];

export const mockViewers = [
  mockExecutors[2],
  {
    name: 'Kate',
    avatar: user2Image,
    color: '',
    online: false,
  },
  mockResponsible,
];

export const mockTags = [
  {
    id: 1,
    name: new Translation('view.viewGeneral.cardSettings.tagTeam'),
    color: '#E5EDFF',
  },
  {
    id: 2,
    name: new Translation('view.viewGeneral.cardSettings.tagApproved'),
    color: '#F9F2DC',
  },
  {
    id: 3,
    name: new Translation('view.viewGeneral.cardSettings.tagMobile'),
    color: '#E0F6E2',
  },
  {
    id: 4,
    name: new Translation('view.viewGeneral.cardSettings.tagDesign'),
    color: '#FBE4F6',
  },
];

export const mockMaterials = {
  filesCount: 2,
  checklistsCount: 2,
  commentsCount: 2,
  blockedByAnotherCount: 1,
  blockedByYourselfCount: 2,
};

export const interactiveButtons = [
  {
    icon: {
      name: svgIcons.CALENDAR,
      color: styles.colorGreyLight,
      height: 14,
      sibling: 'right',
    },
    name: new Translation('tasks.cards.dueDate'),
  },
  {
    icon: {
      name: svgIcons.TASK_PRIORITY,
      color: styles.colorGreyLight,
      height: 14,
      fill: false,
      sibling: 'both',
    },
    name: new Translation('tasks.cards.priority'),
  },
  {
    icon: {
      name: svgIcons.USER,
      color: styles.colorGreyLight,
      height: 14,
      sibling: 'both',
    },
    name: new Translation('tasks.listing.list.users'),
  },
  {
    icon: {
      name: svgIcons.TASK_TAGS,
      color: styles.colorGreyLight,
      height: 14,
      fill: false,
      sibling: 'both',
    },
    name: new Translation('tasks.listing.list.tags'),
  },
  {
    icon: {
      name: svgIcons.SUBTASKS,
      color: styles.colorGreyLight,
      height: 14,
      sibling: 'both',
    },
    name: new Translation('tasks.subtasks.subtasks'),
  },
  {
    icon: {
      name: svgIcons.CHECK_ALL,
      color: styles.colorGreyLight,
      height: 14,
      sibling: 'left',
    },
    name: new Translation('tasks.cards.complete'),
  },
];
