<template>
  <div class="svg-icon-wrapper" :class="[classIconWrapper, $attrs.class]">
    <IconWithIndicator :isActive="isActiveIndicator" :color="indicatorColor">
      <SvgIconPlugin
        :data="icon"
        v-bind="{ ...$attrs, class: '' }"
        :original="isOriginal"
        :scale="$attrs.width || $attrs.height ? $attrs.scale : $attrs.scale || 1"
        class="svg-icon-plugin"
      />
    </IconWithIndicator>
  </div>
</template>

<script lang="ts">
import { type Icon, VueSvgIcon as SvgIconPlugin } from '@yzfe/vue-svgicon';
import { computed, defineComponent, type PropType, ref, watch } from 'vue';

import '@yzfe/svgicon/lib/svgicon.css';
import { svgIcons } from '@/constants/icons';

import IconWithIndicator, { Colors } from './private/IconWithIndicator.vue';
import { allWrappers, type Wrappers, wrappers } from './private/config';

import type { Nullable } from '@/types/utility';

const allSvgIcons = Object.values(svgIcons);
export type SvgIcons = (typeof allSvgIcons)[number];

export { wrappers, Colors as Indicators };
export type { Wrappers };
export default defineComponent({
  name: 'SvgIcon',

  components: { IconWithIndicator, SvgIconPlugin },

  inheritAttrs: false,

  props: {
    name: {
      type: String as PropType<SvgIcons>,
      required: true,
      validator: (value: any) => {
        return allSvgIcons.includes(value);
      },
    },
    wrapper: {
      type: String as PropType<Wrappers>,
      default: wrappers.NONE,
      validator: (value: any) => {
        return allWrappers.includes(value);
      },
    },
    isActiveIndicator: {
      type: Boolean,
      default: false,
    },
    indicatorColor: {
      type: String as PropType<Colors>,
      default: Colors.ErrorLight,
    },
    isOriginal: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const icon = ref();
    const loadIcon = async () => {
      try {
        const _icon: Nullable<Icon> = (await import(`../../../assets/icons/${props.name}.svg`)).default;
        if (_icon) {
          icon.value = _icon;
        }
      } catch (e) {
        throw new Error(`Error when load icon: ${props.name}`);
      }
    };
    watch(() => props.name, loadIcon, { immediate: true });

    const classIconWrapper = computed(() => `svg-icon-wrapper_${props.wrapper}`);

    return { icon, classIconWrapper };
  },
});
</script>

<style lang="scss">
.svg-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  &_sm {
    width: 16px;
    height: 16px;
  }

  &_md {
    width: 24px;
    height: 24px;
  }

  &_lg {
    width: 32px;
    height: 32px;
  }

  &_xl {
    width: 40px;
    height: 40px;
  }
}

.svg-icon-plugin {
  transition: transform 0.3s;
}
</style>
