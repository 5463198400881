import Translation from '@/utils/translation';

export enum SortingCodes {
  Status = 'status',
  Priority = 'priority',
  Stage = 'stage',
  Executor = 'executors',
  Tag = 'task_labels',
}

const sortingItems = [
  {
    code: SortingCodes.Status,
    title: new Translation('constants.tasks.projects.filters.status'),
  },
  {
    code: SortingCodes.Priority,
    title: new Translation('constants.tasks.projects.filters.priority'),
  },
  {
    code: SortingCodes.Stage,
    title: new Translation('constants.tasks.projects.filters.stages'),
  },
  {
    code: SortingCodes.Executor,
    title: new Translation('constants.tasks.projects.filters.executor'),
  },
  {
    code: SortingCodes.Tag,
    title: new Translation('constants.tasks.projects.filters.tag'),
  },
];

export const isSortingExecutor = (code: SortingCodes): code is SortingCodes.Executor => {
  return code === SortingCodes.Executor;
};
export const isSortingTag = (code: SortingCodes): code is SortingCodes.Tag => {
  return code === SortingCodes.Tag;
};
export const formatKeyToApi = (code: SortingCodes): string => {
  if (code === SortingCodes.Executor) return 'executor';
  if (code === SortingCodes.Tag) return 'task_label';
  return code;
};

export const sortingDefault = sortingItems[0];
export default sortingItems;
